var render = function render(){var _vm=this,_c=_vm._self._c;return _c('responsive-ui-list',{attrs:{"useDecrementalScaling":true,"disableXScroll":true,"heading":_vm.heading,"headings":[
    _vm.$t('fields.name'),
    _vm.$t('fields.next_group'),
    _vm.$t('fields.instructor'),
    _vm.$t('models.finished_groups'),
    _vm.$tc('misc.courseEvents.status', 2),
    'search'
],"sort":[
  { prop: 'title', type: false },
  undefined,
  undefined,
  { prop: 'progress', prop2: 'progressPercentage', type: 'level2' },
],"items":_vm.courseEvents,"search_selectors":['title']},scopedSlots:_vm._u([(_vm.$slots.actions)?{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true}:null,{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.title}}),_c('ui-list-data',{staticClass:"whitespace-no-wrap",attrs:{"text":_vm.nextGroupTimeFormatted(props.item)}}),_c('ui-list-data',{attrs:{"type":"tag","text":`${props.item.instructorModel.firstName} ${props.item.instructorModel.lastName}`}}),[(typeof _vm.getPercentage(props.item) === 'number')?_c('ui-list-data-progress',{attrs:{"percentage":_vm.getPercentage(props.item),"progressDetails":props.item.progress}}):_c('ui-list-data-progress',{attrs:{"percentage":0,"progressDetails":{ assignedCount: 0, completedCount: 0}}})],[(props.item.active === true)?_c('ui-list-data',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$tc('misc.courseEvents.active', 1))+" ")]):_vm._e(),(props.item.active === false)?_c('ui-list-data',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$tc('misc.courseEvents.inactive', 1))+" ")]):_vm._e()],_c('ui-list-data',{attrs:{"small":""}},[_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin', 'InstructorAdmin', 'Instructor']}},[_c('ui-button',{attrs:{"type":"green","size":"small","uppercase":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'courseevent-view',
                        params: { id: props.item.id }
                    })}}},[_vm._v(_vm._s(_vm.$t('actions.show_content')))])],1),_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin', 'InstructorAdmin']}},[_c('ui-button',{staticClass:"ml-2",attrs:{"type":"grey","size":"small","uppercase":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'courseevent-edit',
                        params: { id: props.item.id }
                    })}}},[_vm._v(_vm._s(_vm.$t('actions.edit')))])],1)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }